import { useSelector, useDispatch } from 'react-redux';

import { setSyncChanges } from 'actions/player';

export default function usePlayer() {
  const dispatch = useDispatch();
  const playerState = useSelector(state => state.player);

  const stop = () => {
    playerState.progressionPlayer.stopProgression();
    dispatch(
      setSyncChanges({
        isPlaying: false,
        completed: true,
        loopCount: 0,
      }),
    );
  };

  function restart() {
    playerState.progressionPlayer.restartProgression();
    dispatch(
      setSyncChanges({
        isPlaying: true,
        progress: 0,
        completed: false,
        loopCount: 0,
      }),
    );
  }

  return { playerState, stop, restart };
}
