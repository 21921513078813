import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import PropTypes from 'prop-types';

import Creator from 'components/User/Creator';
import { Text } from 'components/Common/Typography';
import { PLACEHOLDER } from 'design-system/inventory/heirarchy';

import { classNamesHelper } from 'utils';

import useTheme from 'selectors/use-theme';
import styles from './Cloned.css';

export default function Cloned({ cloned }) {
  useStyles(styles);
  const appTheme = useTheme();
  /* eslint css-modules/no-unused-class: off */
  return (
    <span className={classNamesHelper([styles.cloned, styles[appTheme]])}>
      <Text heirarchy={PLACEHOLDER}>Cloned </Text>
      {cloned.createdBy && cloned.createdBy.username && (
        <Creator user={cloned.createdBy} />
      )}
    </span>
  );
}

Cloned.propTypes = {
  cloned: PropTypes.object.isRequired,
};
