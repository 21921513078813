import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Tone from 'tone';

import Button from 'components/Button';
import { InlineSpace } from 'components/Common/Spacing';
import { Text } from 'components/Common/Typography';

import { setSyncChanges } from 'actions/player';
import { STATUS_PENDING } from 'types/app';

import { classNamesHelper } from 'utils';

import styles from './PlayStopButton.css';

export default function PlayStopButton() {
  useStyles(styles);
  const dispatch = useDispatch();
  const [playerError, setPlayerError] = useState(null);

  const progressionSettings = useSelector(state => state.progressionSettings);
  const { currentProgression } = useSelector(state => state.progression);
  const playerState = useSelector(state => state.player);
  const appState = useSelector(state => state.app);

  async function toneReady() {
    await Tone.start();
    await Tone.loaded();
  }

  function stopProgression() {
    try {
      playerState.progressionPlayer.stopProgression();
      dispatch(
        setSyncChanges({
          isPlaying: false,
          completed: true,
          loopCount: 0,
        }),
      );
    } catch (e) {
      setPlayerError('Error stoping progression');
    }
  }

  function pauseProgression() {
    try {
      playerState.progressionPlayer.pause();
      dispatch(
        setSyncChanges({
          isPlaying: false,
        }),
      );
    } catch (e) {
      setPlayerError('Error pausing progression');
    }
  }

  async function restartProgressionState() {
    try {
      await toneReady();
      playerState.progressionPlayer.restartProgression();
      dispatch(
        setSyncChanges({
          isPlaying: true,
          progress: 0,
          completed: false,
          loopCount: 0,
        }),
      );
    } catch (e) {
      setPlayerError('Error restarting progression');
    }
  }

  async function playProgression() {
    try {
      await toneReady();
      playerState.progressionPlayer.playProgression();
      dispatch(
        setSyncChanges({
          isPlaying: true,
          progress: 0,
          completed: false,
          loopCount: 0,
        }),
      );
    } catch (e) {
      setPlayerError('Error playing progression');
    }
  }

  function checkCompleted({ chordOrNoteNumber }) {
    if (chordOrNoteNumber === playerState.totalChords - 1) {
      if (progressionSettings.loop === false) {
        stopProgression();
      }
    }
  }

  function handleChordPlay(data) {
    if (
      progressionSettings.arpeggiateStyle ||
      progressionSettings.customArpeggio
    ) {
      checkCompleted({ data, chordOrNoteNumber: data.noteNumber });
      return;
    }
    checkCompleted({ data, chordOrNoteNumber: data.index });
  }

  const checkKey = e => {
    if (e.target.id === 'progressionText') return;
    if (e.keyCode === 32) {
      if (playerState.isPlaying) {
        pauseProgression();
        return;
      }
      playProgression();
    }
  };

  // chordPlay event handler should only be attached once
  // should change when playerState changes
  useEffect(() => {
    setPlayerError(null);
    playerState.progressionPlayer.on('play:progression', handleChordPlay);
    return () => {
      playerState.progressionPlayer.removeListener(
        'play:progression',
        handleChordPlay,
      );
    };
  }, [playerState]);

  useEffect(() => {
    if (!progressionSettings.loop) {
      dispatch(
        setSyncChanges({
          isPlaying: false,
        }),
      );
    }
  }, [progressionSettings]);

  useEffect(
    function listenToKeyboard() {
      document.addEventListener('keydown', checkKey);
      return function cleanup() {
        document.removeEventListener('keydown', checkKey);
      };
    },
    [playerState],
  );

  const isButtonDisabled =
    currentProgression?.loading ||
    !progressionSettings.parsedProgression ||
    progressionSettings.parseStatus === STATUS_PENDING ||
    progressionSettings.setInstrumentStatus === STATUS_PENDING;

  /* eslint css-modules/no-unused-class: off */
  // temp
  /* eslint css-modules/no-undef-class: off */
  return (
    <div
      className={classNamesHelper([styles.container, styles[appState.theme]])}
    >
      <div className={styles['button-container']}>
        <div>
          {playerState.isPlaying && (
            <Button onClick={pauseProgression} buttonType="primary">
              <span>&#10073;&#10073;</span>
            </Button>
          )}
          {!playerState.isPlaying && (
            <Button
              disabled={isButtonDisabled}
              onClick={playProgression}
              buttonType="primary"
            >
              <span>&#9658;</span>
            </Button>
          )}
          <InlineSpace size={12} />
          <Button
            disabled={isButtonDisabled}
            onClick={restartProgressionState}
            buttonType="primary"
          >
            <span>&#8635;</span>
          </Button>
        </div>
        <div>
          {playerError && <Text heirarchy="secondary">{playerError}</Text>}
        </div>
      </div>
    </div>
  );
}
