import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useTheme from 'selectors/use-theme';
import Button from 'components/Button';
import styles from './NumberRange.css';

export default function NumberRange({
  name,
  label,
  value,
  onChange,
  range,
  hideButtons,
  ...rest
}) {
  useStyles(styles);
  const [currentValue, setCurrentValue] = useState(0);
  const theme = useTheme();

  useEffect(
    function setCurrentValueFromProps() {
      setCurrentValue(value);
    },
    [value],
  );

  useEffect(
    function callOnChangeHandler() {
      onChange(Number(currentValue));
    },
    [currentValue],
  );

  const onChangeHandler = e => {
    setCurrentValue(e.target.value);
  };

  const increase = () => {
    if (currentValue === range[1]) {
      return;
    }
    setCurrentValue(Number(currentValue) + 1);
  };

  const decrease = () => {
    if (currentValue === range[0]) {
      return;
    }
    setCurrentValue(Number(currentValue) - 1);
  };
  /* eslint css-modules/no-unused-class: off */
  return (
    <div
      className={[
        styles['number-range-container'],
        styles[`${theme}-number-range-container`],
        !hideButtons && styles.btnContainer,
      ].join(' ')}
      htmlFor={name}
    >
      <span className={styles.label}>{label}</span>
      <span className={styles.btnInputGroupContainer}>
        {!hideButtons && (
          <span className={styles.decreaseBtnContainer}>
            <Button onClick={decrease} title="Decrease">
              -
            </Button>
          </span>
        )}
        <input
          type="number"
          id={name}
          name={name}
          min={range[0]}
          max={range[1]}
          value={currentValue}
          onChange={onChangeHandler}
          {...rest}
          className={styles.numberInput}
        />
        {!hideButtons && (
          <span className={styles.increaseBtnContainer}>
            <Button onClick={increase} title="Increase">
              +
            </Button>
          </span>
        )}
      </span>
    </div>
  );
}

NumberRange.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  range: PropTypes.array,
  hideButtons: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

NumberRange.defaultProps = {
  hideButtons: false,
  range: [-Infinity, Infinity],
};
