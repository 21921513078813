// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--label-font-size:14px;--test-color:#fff}._3jxpN{background-color:red}._3LKc4{color:var(--faded-color)}", ""]);
// Exports
exports.locals = {
	"error-container": "_3jxpN",
	"production-notice": "_3LKc4"
};
module.exports = exports;
