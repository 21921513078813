// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--label-font-size:14px;--test-color:#fff}.og7od{width:100%}.og7od,._2pEr6{display:-ms-flexbox;display:flex}._2pEr6{-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;-ms-flex-wrap:wrap;flex-wrap:wrap}._3fDhe{-ms-flex-align:start;align-items:flex-start}.txsd9{-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between}._1049j{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-wrap:wrap;flex-wrap:wrap}._2JbZQ{-ms-flex-align:space-between;align-items:space-between}._21X_D,._2JbZQ{-ms-flex-pack:center;justify-content:center}._21X_D{-ms-flex-align:center;align-items:center;-ms-flex-wrap:wrap;flex-wrap:wrap}", ""]);
// Exports
exports.locals = {
	"common": "og7od",
	"row": "_2pEr6",
	"row-start": "_3fDhe",
	"row-space-between": "txsd9",
	"column": "_1049j",
	"column-space-between": "_2JbZQ",
	"center-both": "_21X_D"
};
module.exports = exports;
