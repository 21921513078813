import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/Link';
import { Text } from 'components/Common/Typography';
import { TERTIARY } from 'design-system/inventory/heirarchy';

import useTheme from 'selectors/use-theme';
import styles from './Creator.css';

export default function Creator({ user }) {
  useStyles(styles);
  const appTheme = useTheme();
  /* eslint css-modules/no-unused-class: off */
  return (
    <span className={[styles.creator, styles[appTheme]].join(' ')}>
      <Link to={`/${user.username}`} state={{ reRender: true }} cancelBubble>
        <Text heirarchy={TERTIARY}>{user.username}</Text>
      </Link>
    </span>
  );
}

Creator.propTypes = {
  user: PropTypes.object.isRequired,
};
