// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--label-font-size:14px;--test-color:#fff}._30c15{margin:.25em 0}", ""]);
// Exports
exports.locals = {
	"cloned": "_30c15"
};
module.exports = exports;
