import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import { useSelector } from 'react-redux';
import isNil from 'lodash/isNil';

import useTheme from 'selectors/use-theme';

import { classNamesHelper } from 'utils';

import Creator from 'components/User/Creator';
import Cloned from 'components/Cloned';
import DateComponent from 'components/Date';
import Separator from 'components/Separator';
import Link from 'components/Link';
import { Text } from 'components/Common/Typography';
import { PLACEHOLDER, TERTIARY } from 'design-system/inventory/heirarchy';

import styles from './ProgressionInfo.css';

export default function ProgressionInfo() {
  useStyles(styles);
  const theme = useTheme();
  const { currentProgression } = useSelector(state => state.progression);
  const { user } = useSelector(state => state.user);

  const isProgressionLoading =
    isNil(currentProgression) || currentProgression?.loading;

  /* eslint css-modules/no-unused-class: off */
  return (
    <div className={classNamesHelper([styles[theme]])} role="navigation">
      {/* <button
        type="button"
        onClick={share}
        title="Only public and unlisted progression can be shared"
        disabled={
          !progressionState.currentProgression ||
          progressionState.currentProgression.status === 'private'
        }
      >
        Share
      </button> */}
      {currentProgression?.createdBy && (
        <span>
          <Creator user={currentProgression.createdBy} />
        </span>
      )}
      {!isProgressionLoading && <Separator />}
      {currentProgression?.createdAt && (
        <Text heirarchy={PLACEHOLDER}>
          <DateComponent date={currentProgression.createdAt} />
        </Text>
      )}
      {/* progressionState.currentProgression && (
        <span className={styles['item']}>
          <ProgressionStatus
            status={progressionState.currentProgression.privacyStatus}
          />
        </span>
      ) */}
      {currentProgression?.cloned && <Separator />}
      {/* Show if current progression is cloned from someone */}
      {currentProgression?.cloned && (
        <span>
          <Cloned cloned={currentProgression.cloned} />
          <Link to={`/p/${currentProgression.cloned.slug}`}>
            <Text heirarchy={TERTIARY}>{currentProgression.cloned.slug}</Text>
          </Link>
        </span>
      )}
      {/* Show if current auth user has cloned current progression */}
      {user && currentProgression?.cloneExists && <Separator />}
      {user && currentProgression?.cloneExists && (
        <>
          <Text heirarchy={PLACEHOLDER}>You cloned this progression: </Text>
          <span>
            <Link
              to={`/p/${currentProgression.cloneExists.slug}`}
              heirarchy={TERTIARY}
            >
              <span>{currentProgression.cloneExists.slug}</span>
            </Link>
          </span>
        </>
      )}
    </div>
  );
}
