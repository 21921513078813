import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import PropTypes from 'prop-types';

import { classNamesHelper } from 'utils';
import styles from './Spacing.css';

/* eslint css-modules/no-unused-class: off */
export function BlockSpace({ size }) {
  useStyles(styles);
  return (
    <div
      className={classNamesHelper([
        styles.block,
        styles[`block-space-${size}`],
      ])}
    />
  );
}

BlockSpace.propTypes = {
  size: PropTypes.oneOf([12, 25, 50, 75, 100, 150, 200, 300, 400]).isRequired,
};

export function InlineSpace({ size }) {
  useStyles(styles);
  return (
    <span className={classNamesHelper([styles[`inline-space-${size}`]])} />
  );
}

InlineSpace.propTypes = {
  size: PropTypes.oneOf([12, 25, 50, 75, 100, 150, 200, 300, 400]).isRequired,
};
