import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import PropTypes from 'prop-types';
import useTheme from 'selectors/use-theme';
import styles from './CustomCheckBox.css';

export default function CustomCheckBox({ name, label, isSelected, ...rest }) {
  useStyles(styles);
  const theme = useTheme();
  /* eslint css-modules/no-unused-class: off */
  return (
    <label
      className={[
        styles['checkbox-container'],
        styles[`${theme}-checkbox-container`],
      ].join(' ')}
      htmlFor={name}
    >
      <span className={styles.label}>{label}</span>
      <input
        type="checkbox"
        id={name}
        name={name}
        checked={isSelected}
        {...rest}
      />
      <span className={styles.checkmark} />
    </label>
  );
}

CustomCheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
