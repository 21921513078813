import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import PropTypes from 'prop-types';
import formatDistance from 'date-fns/formatDistance';
import parseISO from 'date-fns/parseISO';
import styles from './Date.css';

export default function DateComponent({ date }) {
  useStyles(styles);
  return <span>{formatDistance(parseISO(date), new Date())}</span>;
}

DateComponent.propTypes = {
  date: PropTypes.string.isRequired,
};
