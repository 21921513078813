// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1cyu0{-ms-flex-direction:column-reverse;flex-direction:column-reverse;position:relative;-ms-flex-align:center;align-items:center;-ms-flex-pack:start;justify-content:flex-start}._2qRpc,._1cyu0{display:-ms-flexbox;display:flex}._2qRpc button{padding-bottom:9px}.J7L18{width:70px}._2pJ9F .J7L18{border-radius:0;border-left:none;border-right:none}._3PBtP button{border-top-left-radius:0;border-bottom-left-radius:0}._16uT2 button{border-top-right-radius:0;border-bottom-right-radius:0}._1bBNo{color:var(--font-color-placeholder);font-size:var(--label-font-size)}@media (max-width:768px){.J7L18{width:var(--size-static-400)}._2qRpc button{padding-bottom:11px}}", ""]);
// Exports
exports.locals = {
	"number-range-container": "_1cyu0",
	"btnInputGroupContainer": "_2qRpc",
	"numberInput": "J7L18",
	"btnContainer": "_2pJ9F",
	"increaseBtnContainer": "_3PBtP",
	"decreaseBtnContainer": "_16uT2",
	"label": "_1bBNo"
};
module.exports = exports;
