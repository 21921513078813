// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--label-font-size:14px;--test-color:#fff}._3qRf0{margin:.25em 0}", ""]);
// Exports
exports.locals = {
	"creator": "_3qRf0"
};
module.exports = exports;
