import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import PropTypes from 'prop-types';

import { classNamesHelper } from 'utils';
import styles from './Layout.css';

export function Row({ children }) {
  useStyles(styles);
  return <div className={styles.row}>{children}</div>;
}

Row.propTypes = {
  children: PropTypes.node.isRequired,
};

export function RowStart({ children }) {
  useStyles(styles);
  return (
    <div className={classNamesHelper([styles.row, styles['row-start']])}>
      {children}
    </div>
  );
}

RowStart.propTypes = {
  children: PropTypes.node.isRequired,
};

export function RowSpaceBetween({ children }) {
  useStyles(styles);
  return (
    <div
      className={classNamesHelper([styles.row, styles['row-space-between']])}
    >
      {children}
    </div>
  );
}

RowSpaceBetween.propTypes = {
  children: PropTypes.node.isRequired,
};

export function Column({ children }) {
  useStyles(styles);
  return <div className={styles.column}>{children}</div>;
}

Column.propTypes = {
  children: PropTypes.node.isRequired,
};

export function ColumnSpaceBetween({ children }) {
  useStyles(styles);
  return (
    <div
      className={classNamesHelper([
        styles.column,
        styles['column-space-between'],
      ])}
    >
      {children}
    </div>
  );
}

ColumnSpaceBetween.propTypes = {
  children: PropTypes.node.isRequired,
};

export function CenterBoth({ children }) {
  useStyles(styles);
  return (
    <div className={classNamesHelper([styles.common, styles['center-both']])}>
      {children}
    </div>
  );
}

CenterBoth.propTypes = {
  children: PropTypes.node.isRequired,
};
